import React from "react";
import useStyles from "./Enquiry.styles";
import {
  Box,
  FormControlLabel,
  RadioGroup,
  Typography
} from "@material-ui/core";
import RadioButton from "../CustomRadio/CustomRadio.component";

const StepThree = ({ stepThreeData, selectAnswer, stepAnswers }) => {
  const classes = useStyles();
  return stepThreeData.map((stepThreeArr) => (
    <div className={classes.bottomSpacing}>
      <Typography
        variant="h5"
        className={`${classes.question} ${classes.questionLabel}`}
      >
        {stepThreeArr.question}
      </Typography>
      <RadioGroup
        row
        name="controlled-radio-buttons-group"
        value={selectAnswer[stepThreeArr.question]}
        onChange={(event) =>
          selectAnswer({ event, question: stepThreeArr.question })
        }
      >
        {stepThreeArr.answers.map((answer, index) => {
          return (
            <Box className={classes.radioBoxCover} key={index + 1}>
              <FormControlLabel
                className={classes.formLabelStyle}
                checked={
                  answer.toString() === stepAnswers[stepThreeArr.question]
                }
                value={answer}
                control={<RadioButton />}
                label={<span className={classes.answerLabel}>{answer}</span>}
              />
            </Box>
          );
        })}
      </RadioGroup>
    </div>
  ));
};
export default StepThree;
