import React from "react";
import { Typography, Grid } from "@material-ui/core";
import Image2 from "../../../assets/image2.png";
import useStyles from "./ImageText.styles";
import { useTranslation } from "react-i18next";

export function ImageText(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <section>
      <div className={classes.sectionHeadingWrapper}>
        <Typography variant="h4" className={classes.sectionHeading}>
          {t("IMAGE_TEXT.SECTION_HEADING")}
        </Typography>
      </div>
      <div className={classes.imageTextCover}>
        <Typography variant="h5" className={classes.mediaTextTitle}>
          {t("IMAGE_TEXT.HEADING")}
        </Typography>
        <Grid container className={classes.mediaTextMainGrid}>
          <Grid item xs={12} md={6}>
            <figure>
              <img src={Image2} alt="" className={classes.imageComponent} />
            </figure>
          </Grid>
          <Grid item xs={12} md={6}>
            <div className={classes.textWrapper}>
              <Typography
                variant="subtitle1"
                className={classes.mediaTextSubtitle}
              >
                {t("IMAGE_TEXT.TITLE1")}
              </Typography>
              <Typography
                paragraph
                variant="body1"
                className={classes.mediaTextPara}
              >
                {t("IMAGE_TEXT.PARA1")}
              </Typography>
            </div>
            <div className={classes.textWrapper}>
              <Typography
                variant="subtitle1"
                className={classes.mediaTextSubtitle}
              >
                {t("IMAGE_TEXT.TITLE2")}
              </Typography>
              <Typography
                paragraph
                variant="body1"
                className={classes.mediaTextPara}
              >
                {t("IMAGE_TEXT.PARA2")}
              </Typography>
            </div>
            <div className={classes.textWrapper}>
              <Typography
                variant="subtitle1"
                className={classes.mediaTextSubtitle}
              >
                {t("IMAGE_TEXT.TITLE3")}
              </Typography>
              <Typography
                paragraph
                variant="body1"
                className={classes.mediaTextPara}
              >
                {t("IMAGE_TEXT.PARA3")}
              </Typography>
            </div>
          </Grid>
        </Grid>
      </div>
    </section>
  );
}
