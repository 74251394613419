import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Typography } from "@material-ui/core";
import useStyles from "./Testimonials.styles";
import Slide from "@material-ui/core/Slide";

const testimonialData = [
  {
    author: "Steven McKenzie, Dallas, Texas",
    content:
      "My son has allergies, so we were looking to remove our carpet and put in wood flooring. We thought we couldn’t afford it, but we decided to get a few quotes from FixMyHome to help us budget. To our surprise we received reasonable quotes we could afford and love our new floors."
  },
  {
    author: "Mitchell Snodgrass, Glendale, California",
    content:
      "My roof needed to be patched up before the rainy season began. We’d put off looking for a contractor, because of the hassle and now we were running out of time. A friend told us about fixMyHome and we couldn’t be more thankful."
  },
  {
    author: "Samantha Sawyer, Chicago, Illinois",
    content:
      "Our shower was awful and needed to be updated. We used FixMyHome to find a contractor. It was so much easier than browsing business review sites. I love my new shower!."
  }
];

export default function MainPage(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [slideIndex, setSlideIndex] = useState(1);
  const [direction, setDirection] = useState("left");
  const timeoutRef = React.useRef(null);
  const delay = 5000;

  const resetTimeout = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  };

  React.useEffect(() => {
    resetTimeout();
    timeoutRef.current = setTimeout(() => nextSlide(), delay);

    return () => {
      resetTimeout();
    };
  }, [slideIndex]);

  const nextSlide = () => {
    setDirection("left");
    if (slideIndex !== testimonialData.length) {
      setSlideIndex(slideIndex + 1);
    } else if (slideIndex === testimonialData.length) {
      setSlideIndex(1);
    }
  };

  const prevSlide = () => {
    setDirection("right");
    if (slideIndex !== 1) {
      setSlideIndex(slideIndex - 1);
    } else if (slideIndex === 1) {
      setSlideIndex(testimonialData.length);
    }
  };

  return (
    <div className={classes.testimonialSection}>
      {testimonialData.map((obj, index) => {
        return (
          <Slide
            key={index}
            timeout={{
              appear: 300,
              enter: 500,
              exit: 300
            }}
            in={slideIndex === index + 1}
            direction={direction}
            className={
              slideIndex === index + 1
                ? `${classes.textWrapper} ${classes.activeTextWrapper}`
                : classes.textWrapper
            }
          >
            <div>
              <Typography
                paragraph
                variant="body2"
                className={classes.testimonialText}
              >
                {obj.content}
              </Typography>
              <Typography paragraph variant="body2" className={classes.author}>
                {obj.author}
              </Typography>
            </div>
          </Slide>
        );
      })}
      <a onClick={() => prevSlide()}>
        <div className={`${classes.sliderBtn} ${classes.prev}`}>&lt;</div>
      </a>
      <a onClick={() => nextSlide()}>
        <div className={`${classes.sliderBtn} ${classes.next}`}>&gt;</div>
      </a>
    </div>
  );
}
