export const DATE_FORMAT = "yyyy-MM-DD";
export const DATE_FORMAT_MM_DD_YYYY = "MM/DD/YYYY";
export const DATE_TIME_FORMAT_YYYY_MM_DD_HH_MM_SS = "YYYY-MM-DD HH:mm:ss";
export const DATE_TIME_FORMAT_YYYY_MM_DD_T_HH_MM_SS = "YYYY-MM-DDTHH:mm:ss";
export const DATE_TIME_FORMAT_MMM_D_H_MMA = "MMM D - h:mma";
export const DATE_TIME_FORMAT_M_D_YY_H_MMA = "M/D/YY - h:mma";
export const TIME_FORMAT = "HH:mm:ssa";
export const US_PHONE_NUMBER_FORMAT =
  /^(\([0-9]{3}\)|[0-9]{3}-)[0-9]{3}-[0-9]{4}$/;

export const COLOR_OPTIONS = {
  orange: "#FF8022",
  green: "#008000",
  grey: "#CCCCCC"
};

export const PURCHASE_TIME_FRAME = {
  question: "When would you like to get this done?",
  answers: [
    "Emergency Service",
    "Timing Flexible",
    "Within 1 Week",
    "1 or 2 Weeks",
    "Within 1 month",
    "1-3 months"
  ]
};
export const RESIDENTIAL_INFORMATION = [
  { question: "Residential", answers: ["Yes", "No"] },
  { question: "Homeowner", answers: ["Yes", "No"] }
];
export const PRIVACY_POLICY_LINK = "https://fixmyho.me/privacypolicy/"