import React from "react";
import useStyles from "./Enquiry.styles";
import {
  Box,
  FormControlLabel,
  RadioGroup,
  Typography
} from "@material-ui/core";
import RadioButton from "../CustomRadio/CustomRadio.component";

const StepTwo = ({ stepTwoData, selectAnswer, stepAnswers }) => {
  const classes = useStyles();
  return (
    <>
      <Typography
        variant="h5"
        className={`${classes.question} ${classes.questionLabel}`}
      >
        {stepTwoData.question}
      </Typography>
      <RadioGroup
        row
        name="controlled-radio-buttons-group"
        value={"Timeframe"}
        onChange={(event) => selectAnswer({ event, question: "Timeframe" })}
      >
        {stepTwoData.answers.map((answer, index) => {
          return (
            <Box className={classes.radioBoxCover} key={index + 1}>
              <FormControlLabel
                className={classes.formLabelStyle}
                checked={answer.toString() === stepAnswers["Timeframe"]}
                value={answer}
                control={<RadioButton/>}
                label={<span className={classes.answerLabel}>{answer}</span>}
              />
            </Box>
          );
        })}
      </RadioGroup>
    </>
  );
};
export default StepTwo;
