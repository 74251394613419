import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  FormControlLabel,
  RadioGroup,
  Grid,
  TextField
} from "@material-ui/core";
import RadioButton from "../CustomRadio/CustomRadio.component";
import useStyles from "./Service.styles";
import theme from "../../../CustomTheme.js";
import jsyaml from "js-yaml";
import ServiceImage from "../../../assets/service@2x.png";
import EnquiryComponent from "../Enquiry/Enquiry.component";

export default function Service(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [value, setValue] = useState("");
  const [services, setServices] = useState([]);
  const [zip, setZip] = useState(props.zip);
  const [project, setProject] = useState({});
  useEffect(() => {
     var d =
     document.getElementsByClassName("App")[0];
    setServices(JSON.parse(d.getAttribute("settings")));
  }, []);

  const handleChange = (event) => {
    setValue(event.target.value);
    //send to next question
    const selectedService = services.find(
      (item) => item.value === event.target.value
    );
    setProject(selectedService);
    // send this to project type compoenent -> steps will be created according to questions array
  };

  const handleZipCodeInput = (e) => {
    let input = e.target.value;
    if (
      !input ||
      (input[input.length - 1].match("[0-9]") && input[0].match("[1-9]"))
    ) {
      setZip(input);
    }
  };

  const reset = () => {
    setValue("");
    setProject({});
  };

  return (
    <>
      {Object.keys(project).length === 0 ? (
        <Box className={classes.mainContainer}>
          <Grid container>
            <h3 className={`${classes.title} ${classes.smallScreenTitle}`}>
              {t("SERVICE_TITLE")}
            </h3>
            <img src={ServiceImage} alt="" className={classes.imageComponent} />
            <Grid item className={classes.fieldContainer}>
              <h3 className={`${classes.title} ${classes.hideTitle}`}>
                {t("SERVICE_TITLE")}
              </h3>
              <TextField
                theme={theme}
                label={t("LOCATION.ZIPCODE_LABEL")}
                onChange={handleZipCodeInput}
                variant="standard"
                value={zip}
                className={classes.zipTextInput}
                InputLabelProps={{ style: { color: theme.palette.primary.main } }}
              />
              <div className={classes.checkboxWrapper}>
                <h3 className={`${classes.title} ${classes.subTitleFont}`}>
                  {t("SELECT_SERVICE")}
                </h3>
                <RadioGroup
                  row
                  aria-label="servicetype"
                  value={value}
                  onChange={handleChange}
                >
                  {services.map((service) => {
                    return (
                      <Box
                        key={service.value}
                        className={classes.radioBoxCover}
                      >
                        <FormControlLabel
                          value={service.value}
                          control={<RadioButton />}
                          label={
                            <span className={classes.answerLabel}>
                              {service.title}
                            </span>
                          }
                        />
                      </Box>
                    );
                  })}
                </RadioGroup>
              </div>
            </Grid>
          </Grid>
        </Box>
      ) : (
        <EnquiryComponent
          services={services}
          project={project}
          zipcode={zip}
          resetHandler={reset}
        />
      )}
    </>
  );
}
