import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  modalWrapper: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "transparent",
    padding: "0 20px",
    width: "65vw",
    maxWidth: "1000px",
    height: "70vh",
    overflow: "auto",
    margin: "0 auto",
    alignItems: "center",
    [theme.breakpoints.between("xs", "sm")]: {
      padding: 0,
      alignItems: "center",
      overflow: "hidden"
    }
  },
  question: {
    textAlign: "left",
    color: theme.palette.primary.black,
    fontSize: "1.2rem",
    textTransform: "capitalize",
    lineHeight: theme.typography.lineHeight.heading,
    fontWeight: theme.typography.weights.semiBold,
    marginBottom: 20,
    [theme.breakpoints.between("xs", "sm")]: {
      fontSize: "1.4rem"
    }
  },
  questionLabel: {
    [theme.breakpoints.between("xs", "sm")]: {
      textAlign: "center"
    }
  },
  container: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    overflow: "auto",
    display: "none",
    margin: "auto",
    transition: "opacity ease-in-out 0.4s"
  },
  lastStepContainer: {
    width: "100%",
    height: "100%",
    overflow: "auto"
  },
  activeData: {
    display: "block",
    alignSelf: "center"
  },
  nextBtn: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.white,
    marginLeft: 10,
    fontSize: "1rem",
    fontWeight: theme.typography.weights.medium,
    width: "128px",
    height: "40px",
    whiteSpace: "nowrap"
  },
  quoteBtn: {
    backgroundColor: theme.palette.primary.main,
    fontWeight: theme.typography.weights.semiBold,
    color: "white",
    fontSize: "0.9rem",
    width: "90%",
    maxWidth: "380px",
    marginTop: 30,
    padding: "4px 0",
    [theme.breakpoints.between("xs", "sm")]: {
      width: "90%"
    }
  },
  prevBtn: { fontSize: "1rem", fontWeight: theme.typography.weights.semiBold },
  radioBoxCover: {
    width: "15rem",
    margin: "18px 18px 0 0",
    display: "flex",
    alignItems: "center",
    border: `1px solid ${theme.palette.primary.greyBorder}`,
    borderRadius: "5px",
    paddingLeft: "5px",
    [theme.breakpoints.between("xs", "sm")]: {
      margin: "5px auto"
    }
  },
  questionPopupWrapper: {
    position: "relative",
    width: "100%",
    height: "100%",
    maxWidth: "900px"
  },

  formLabelStyle: {
    textTransform: "capitalize"
  },
  answerLabel: {
    fontSize: "0.875rem",
    lineHeight: theme.typography.lineHeight.button,
    fontWeight: theme.typography.weights.medium
  },
  bottomSpacing: {
    marginBottom: 30
  },
  topSpacing: {
    marginTop: 20
  },
  bottomSpacingXs: {
    [theme.breakpoints.between("xs", "sm")]: {
      marginBottom: 30
    }
  },
  imageComponent: {
    [theme.breakpoints.between("xs", "sm")]: {
      width: "100%",
      maxWidth: "280px"
    },
    width: "100%",
    maxWidth: "430px",
    height: "auto"
  },
  confirmationTitle: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center"
  },
  title: {
    fontSize: "1.5rem",
    lineHeight: theme.typography.lineHeight.title,
    fontWeight: theme.typography.weights.semiBold,
    marginTop: 30,
    [theme.breakpoints.between("xs", "sm")]: {
      fontSize: "1.2rem"
    }
  },
  titleLength: {
    [theme.breakpoints.up("sm")]: {
      width: "25ch"
    }
  },
  textCenter: { textAlign: "center" },
  //stepper styling
  stepperRoot: {
    [theme.breakpoints.up("sm")]: {
      width: "500px",
      margin: "0 auto 15px auto"
    }
  },
  btnWrapper: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    paddingBottom: "2px",
    marginTop: "20px"
  },
  stepperIcon: {
    height: 25,
    width: 25,
    borderRadius: "50%",
    backgroundColor: theme.palette.primary.main,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: theme.palette.primary.white,
    fontSize: "0.9rem",
    padding:"2px"
  },
  stepperPadding:{
      paddingRight: "2px"
  },
  stepperIconInActive: {
    backgroundColor: theme.palette.primary.disabled,
    color: "#A7A7A7"
  },
  stepCompletedIcon: {
    fontSize: "1.125rem"
  },
  textInput: {
    backgroundColor: theme.palette.primary.white,
    justifyContent: "center",
    border: "0",
    paddingRight: 15,
    margin: "20px",
    [theme.breakpoints.between("xs", "sm")]: {
      marginTop: 20,
      marginBottom: 20,
      width: "100%"
    }
  },
  textInputFull: {
    backgroundColor: theme.palette.primary.white,
    justifyContent: "center",
    border: "0",
    paddingRight: 15,
    width: "90%",
    [theme.breakpoints.between("xs", "sm")]: {
      width: "100%",
      marginTop: 20,
      marginBottom: 20
    }
  },
  fullWidth: {
    width: "95%"
  },
  zipLabel: {
    color: theme.palette.primary.yellow,
    fontSize: "14px",
    fontWeight: theme.typography.weights.medium
  },
  gridMargin: {
    marginLeft: "40px",
    [theme.breakpoints.between("xs", "sm")]: {
      marginLeft: "0px"
    }
  },
  grid: {
    minWidth: "410px",
    [theme.breakpoints.between("xs", "sm")]: {
      marginTop: 20,
      marginBottom: 20,
      width: "100%",
      minWidth: "0px",
      marginRight: "0px"
    }
  },
  checkboxWrapper: {
    marginTop: 20,
    marginBottom: 20,
    marginRight: "auto",
    [theme.breakpoints.between("xs", "sm")]: {
      margin: 0
    }
  },

  checkBox: {
    borderRadius: 3,
    border: "1px solid " + theme.palette.primary.main,
    width: 15,
    height: 15,
    background: theme.palette.primary.white,
    marginLeft: -8
  },
  tickIcon: {
    fontSize: 15,
    color: theme.palette.primary.white,
    position: "absolute",
    top: 0,
    left: 0
  },
  checkedChecked: {
    backgroundColor: "orange",
    justifyContent: "center",
    alignItems: "center",
    position: "relative"
  },
  checkText: {
    color: theme.palette.primary.dark_grey,
    fontSize: "0.8rem",
    fontWeight: theme.typography.weights.semiBold,
    textDecoration: "none",
    "&:hover": {
      color: theme.palette.primary.yellow
    },
    [theme.breakpoints.between("xs", "sm")]: {
      fontSize: "0.7rem"
    }
  },
  policyText: {
    marginTop: "32px",
    color: theme.palette.primary.grey_text,
    fontWeight: theme.typography.weights.semiBold,
    fontSize: "0.75rem",
    [theme.breakpoints.between("xs", "sm")]: {
      fontSize: "0.6rem",
      textAlign: "center"
    }
  },
  policyLink: {
    color: theme.palette.primary.blue,
    textDecoration: "none",
    whiteSpace: "nowrap"
  }
}));
