import React, { useState } from "react";

import { makeStyles } from "@material-ui/core/styles";
import CustomModal from "../../components/UI/Modal/Modal.component";
import Service from "../../components/UI/Service/Service.component";
import ZipCodeSearch from "../../components/UI/ZipSearch/ZipSearch.component";
import { ImageText } from "../../components/UI/ImageText/ImageText.component";
import BackgroundImage from "../../assets/landing_page_bg.png";
import GRADIENT_BG from "../../assets/shade_bg@2x.png";

import Testimonials from "../../components/UI/Testimonials/Testimonials.components";
import Header from "../../components/UI/Header/Header.component";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: 0,
    backgroundColor: theme.palette.primary.white,
    color: theme.palette.primary.grey
  },
  content: {
    display: "block"
  },
  mainDiv: {
    position: "relative",
    background: `url(${GRADIENT_BG}), url(${BackgroundImage})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    marginTop: 64,
    [theme.breakpoints.between("xs", "sm")]: {
      marginTop: 56
    }
  }
}));

export default function MainPage(props) {
  const classes = useStyles();
  const [openModal, setOpenModal] = useState(false);
  const [zipCode, setZipCode] = useState("");

  const onclose = () => {
    setOpenModal(false);
  };

  return (
    <>
      <Header />
      <div className={classes.mainDiv}>
        <ZipCodeSearch
          zipCodeValue={zipCode}
          setZipCode={setZipCode}
          setOpenModal={setOpenModal}
        />
      </div>
      <CustomModal open={openModal} closeHandler={onclose} isTitle={false}>
        <Service zip={zipCode} />
      </CustomModal>
      <ImageText />
      <Testimonials />
    </>
  );
}
