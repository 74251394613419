import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  mainContainer: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "transparent",
    marginLeft: 10,
    paddingLeft: 60,
    paddingTop: 60,
    paddingBottom: 60,
    [theme.breakpoints.between("xs", "sm")]: {
      width: "100%",
      marginLeft: 0,
      alignItems: "center",
      paddingLeft: 0
    }
  },
  title: {
    textAlign: "left",
    color: theme.palette.primary.white,
    maxWidth: "25ch",
    fontSize: "1.75rem",
    lineHeight: theme.typography.lineHeight.title,
    fontWeight: theme.typography.weights.semiBold,
    [theme.breakpoints.between("xs", "sm")]: {
      textAlign: "center",
      fontSize: "1.4rem"
    }
  },
  zipTextInput: {
    backgroundColor: theme.palette.primary.white,
    fontSize: "0.875rem",
    borderRadius: "35px",
    alignSelf: "left",
    maxWidth: "14.5rem",
    maxHeight: "2.31rem",
    border: "2px solid transparent",
    "& fieldset": {
      border: 0
    },
    "&:focus-within": {
      borderColor: theme.palette.primary.yellow
    }
  },

  checkboxWrapper: {
    marginTop: 10,
    marginRight: "auto",
    "& a": {
      color: theme.palette.primary.white,
      textDecoration: "none"
    },
    "& a:hover": {
      color: theme.palette.primary.yellow
    },
    [theme.breakpoints.between("xs", "sm")]: {
      margin: 0
    }
  },

  checkBox: {
    borderRadius: 3,
    width: 15,
    height: 15,
    backgroundColor: theme.palette.primary.white,
    marginLeft: -8
  },
  tickIcon: {
    fontSize: 15,
    color: theme.palette.primary.white,
    position: "absolute",
    top: 0,
    left: 0
  },
  checkedChecked: {
    backgroundColor: "grey",
    justifyContent: "center",
    alignItems: "center",
    position: "relative"
  },
  policyText: {
    color: theme.palette.primary.white,
    fontSize: "0.75rem",
    [theme.breakpoints.between("xs", "sm")]: {
      fontSize: "0.6rem"
    }
  },
  zipSearchBtn: {
    width: "1.56rem",
    height: "1.56rem",
    fontSize: "10px"
  },
  zipSearchBtnEnable: {
    backgroundColor: theme.palette.primary.main
  },
  goIcon: { fontSize: "1rem" }
}));
