export const formatNumber = (num) => {
  return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
};
export const replaceUnderScoreWithSpace = (str) => {
  return str.replace(/_/g, " ");
};
export const not = (a, b) => {
  return a.filter((value) => b.indexOf(value) === -1);
};

export const intersection = (a, b) => {
  return a.filter((value) => b.indexOf(value) !== -1);
};

export const union = (a, b) => {
  return [...a, ...not(b, a)];
};

export const emailValidate = (email) => {
  const pattern = new RegExp(
    /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
  );
  return pattern.test(email);
};
