import React from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import LOGO from "../../../assets/logo@2x.png";
import useStyles from "./Header.styles";

export default function MainPage(props) {
  const classes = useStyles();
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0
  });

  return (
    <AppBar elevation={trigger ? 4 : 0} className={classes.headerSetting}>
      <Toolbar className={classes.headerLogoWrapper}>
        <a href={"/"}>
          <img src={LOGO} alt="fix my home logo" className={classes.logo} />
        </a>
      </Toolbar>
    </AppBar>
  );
}
