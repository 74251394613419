import React from "react";
import useStyles from "./Enquiry.styles";
import { Typography, Grid, Button } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import SERVICE_IMAGE from "../../../assets/service@2x.png";

const StepConfirmation = ({ submitAnother }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Grid container>
      <Grid xs={12} md={6} item className={classes.textCenter}>
        <img src={SERVICE_IMAGE} alt="" className={classes.imageComponent} />
      </Grid>
      <Grid xs={12} md={6} item className={classes.confirmationTitle}>
        <Typography
          variant="h4"
          className={`${classes.title} ${classes.titleLength}`}
        >
          {t("SUBMIT_CONFIRMATION_TITLE")}
        </Typography>
        <Typography variant="h5" className={classes.title}>
          {t("SUBMIT_CONFIRMATION_SUBTITLE")}
        </Typography>
        <Button
          variant="contained"
          className={`${classes.quoteBtn}`}
          onClick={() => submitAnother()}
        >
          {t("SUBMIT_ANOTHER")}
        </Button>
      </Grid>
    </Grid>
  );
};
export default StepConfirmation;
