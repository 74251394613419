import React from "react";
import PropTypes from "prop-types";
import { Modal, Button } from "@material-ui/core/";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import useStyles from "./Modal.styles";
import clsx from "clsx";

const CustomModal = (props) => {
  const classes = useStyles();
  const {
    open,
    isTitle,
    editHandler,
    editText,
    closeHandler,
    children,
    parentStyle = null
  } = props;
  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={clsx(classes.modal)}
        open={open}
        closeAfterTransition
        disableEnforceFocus
        // onClose={(event, reason) => {
        //   if (reason !== 'backdropClick') {
        //     onClose(event, reason);
        //   }
        // }}
      >
        <div className={clsx(classes.paper, parentStyle)}>
          <div className={parentStyle}>
            <div className={classes.header}>
              {editHandler && editText ? (
                <Button
                  variant="contained"
                  className={classes.btn}
                  onClick={editHandler}
                >
                  {editText}
                </Button>
              ) : null}
              <div>
                <CloseRoundedIcon
                  fontSize="medium"
                  onClick={closeHandler}
                  className={classes.closeIcon}
                />
              </div>
            </div>
            {isTitle ? (
              <div className={classes.contentWithTitle}>{children}</div>
            ) : (
              <div className={classes.contentWithoutTitle}>{children}</div>
            )}
          </div>
        </div>
      </Modal>
    </div>
  );
};

CustomModal.propTypes = {
  open: PropTypes.bool.isRequired,
  isTitle: PropTypes.bool.isRequired,
  editHandler: PropTypes.func,
  editText: PropTypes.string,
  closeHandler: PropTypes.func.isRequired,
  children: PropTypes.node,
  parentStyle: PropTypes.string
};

export default CustomModal;
