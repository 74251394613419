import React, { useEffect, useState } from "react";
import { Redirect, Route } from "react-router-dom";


const CustomRoute = (props) => {

  const [returnedRoute, setReturnedRoute] = useState("");

  /**
   * Redirects to permission error page
   * @param {*} pageName
   */
  const redirectToErrorPage = (pageName) => {
    return (
      <Redirect
        to={{
          pathname: "/error",
          pageName: pageName,
        }}
      />
    );
  };

  useEffect(() => {
    return setReturnedRoute(<Route {...props} />);
  }, [props]);

  return <>{returnedRoute}</>;
};

export default CustomRoute;
