import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  mainContainer: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "transparent",
    overflow: "auto",
    margin: "0 auto",
    alignItems: "center",
    [theme.breakpoints.between("xs", "sm")]: {
      width: "70vw",
      height: "80vh",
      padding: 0,
      alignItems: "center"
    }
  },
  imageComponent: {
    [theme.breakpoints.between("xs", "sm")]: {
      maxWidth: "280px",
      margin: "auto"
    },
    maxWidth: "430px",
    height: "auto"
  },
  fieldContainer: {
    maxWidth: "580px"
  },
  title: {
    textAlign: "left",
    color: theme.palette.primary.black,
    fontSize: "1.5rem",
    width: "22ch",
    lineHeight: theme.typography.lineHeight.title,
    fontWeight: theme.typography.weights.semiBold,
    [theme.breakpoints.between("xs", "sm")]: {
      fontSize: "1.3rem"
    }
  },
  answerLabel: {
    fontSize: "0.875rem",
    lineHeight: theme.typography.lineHeight.button,
    fontWeight: theme.typography.weights.medium
  },
  subTitleFont: {
    fontSize: "1.25rem"
  },
  smallScreenTitle: {
    display: "none",
    [theme.breakpoints.between("xs", "sm")]: {
      display: "block",
      fontSize: "1.3rem"
    }
  },
  hideTitle: {
    [theme.breakpoints.between("xs", "sm")]: {
      display: "none"
    }
  },

  radioBoxCover: {
    width: "15rem",
    margin: "18px 18px 0 0",
    border: `1px solid ${theme.palette.primary.greyBorder}`,
    borderRadius: "5px",
    paddingLeft: "10px"
  },
  zipTextInput: {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.white,
    justifyContent: "center",
    border: "0",
    width: "92%",
    [theme.breakpoints.between("xs", "sm")]: {
      marginTop: 20,
      marginBottom: 20,
      width: "100%"
    }
  }
}));
