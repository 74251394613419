import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  headerSetting: {
    backgroundColor: theme.palette.primary.white,
    borderWidth: "2px",
    borderTopStyle: "solid",
    borderColor: theme.palette.primary.main
  },
  headerLogoWrapper: {
    marginLeft: "20%",
    [theme.breakpoints.between("xs", "sm")]: {
      marginLeft: "10%"
    }
  },
  logo: { width: "12.5rem" }
}));
