import { makeStyles } from "@material-ui/core/styles";
import TESTIMONIAL_BG from "../../../assets/testimonial_bg.png";

export default makeStyles((theme) => ({
  testimonialText: {
    fontSize: "0.875rem",
    color: theme.palette.primary.white,
    lineHeight: theme.typography.lineHeight.body,
    marginBottom: "1rem",
    [theme.breakpoints.between("xs", "sm")]: {
      fontSize: "0.8rem",
    }
  },
  author: {
    color: theme.palette.primary.yellow,
    position: "relative",
    paddingTop: "1rem",
    fontSize: "0.875rem",
    "&::before": {
      content: '""',
      position: "absolute",
      left: "47%",
      top: 0,
      width: "4ch",
      height: "2px",
      backgroundColor: theme.palette.primary.yellow
    }
  },

  textWrapper: {
    maxWidth: "700px",
    width: "70%",
    margin: "3rem auto",
    opacity: 0,
    position: "absolute",
    transition: "opacity ease-in-out 0.4s"
  },

  activeTextWrapper: {
    opacity: 1
  },
  testimonialSection: {
    background: `url(${TESTIMONIAL_BG})`,
    backgroundPosition: "100%",
    backgroundSize: "cover",
    position: "relative",
    display: "flex",
    justifyContent: "center",
    overflow: "hidden",
    maxWidth: "100%",
    height: "270px",
    opacity: 1,
    [theme.breakpoints.between("xs", "sm")]: {
      paddingBottom: 40
    },
    paddingBottom: "0",
    "&::after": {
      content: '""',
      position: "absolute",
      zIndex: 1,
      left: 0,
      bottom: 0,
      height: "50px",
      width: "100%",
      backgroundColor: "black"
    },
    "&::before": {
      content: '""',
      position: "absolute",
      left: 0,
      top: 0,
      height: "100%",
      width: "100%",
      backgroundColor: "rgba(0, 0, 0, 0.7)"
    }
  },
  sliderBtn: {
    display: "flex",
    position: "absolute",
    height: "36px",
    width: "36px",
    justifyContent: "center",
    alignItems: "center",
    color: theme.palette.primary.white,
    borderRadius: "50%",
    fontSize: "0.875rem",
    border: "solid 1px",
    borderColor: theme.palette.primary.white,
    "&:hover": {
      borderColor: theme.palette.primary.main,
      color: theme.palette.primary.main
    }
  },
  prev: {
    left: "10%",
    top: "40%",
    [theme.breakpoints.between("xs", "sm")]: {
      left: "4%",
    }
  },
  next: {
    right: "10%",
    top: "40%",
    [theme.breakpoints.between("xs", "sm")]: {
      right: "4%",
    }
  }
}));
