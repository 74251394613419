import React, { useEffect, useState } from "react";
import "./App.css";

import { Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import themeDefault from "./CustomTheme.js";
import { MuiThemeProvider } from "@material-ui/core/styles";
import Loading from "./containers/Backdrop/BackDrop";
import { SnackbarProvider } from "notistack";
import { Slide } from "@material-ui/core";
import CustomRoutes from "./containers/CustomRoutes/CustomRoutes";
import jsyaml from "js-yaml";

function App() {
  const history = createBrowserHistory();

  const routes = () => {
    return (
      <Router history={history}>
        <CustomRoutes />
        <Loading />
      </Router>
    );
  };

  function TransitionUp(props) {
    return <Slide {...props} direction="up" />;
  }

  useEffect(() => {
    fetch(process.env.PUBLIC_URL + "/configuration.json")
      .then((r) => r.text())
      .then((text) => {
        const json = jsyaml.load(text);
        document.getElementsByClassName("App")[0].setAttribute("settings", JSON.stringify(json.services));
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <MuiThemeProvider theme={themeDefault}>
      <SnackbarProvider
        preventDuplicate
        maxSnack={10}
        TransitionComponent={TransitionUp}
        className="customAlert"
      >
        <div className="App">
          <div className="content">
            <div  
            className="routes-container">{routes()}</div>
          </div>
        </div>
      </SnackbarProvider>
    </MuiThemeProvider>
  );
}

export default App;
