import axios from "axios";
import i18n from "../i18n";

/**
 *
 * @param {string} url endpoint url to attach with base url
 * @param {string} method method to call rest api: get, post, put
 * @param {object} data object to be send with rest api as post data
 * @param {boolean} auth if true jwt token will be attached to authentication header
 */
export const callRESTAPI = async (
  url,
  method = "get",
  data = null,
  params = null,
  allowCache = null,
  auth = true,
  responseType = null,
  headers = {
    'Authorization': 'Token 7bcd23695eb67018babcf685a4903a9bbca3bd41'
  }
) => {
  try {
    return await axios({
      url,
      params,
      method,
      data,
      responseType,
      headers
    });
  } catch (error) {
    if (!error.response) {
      if (error.message === "Network Error") {
        error.message = i18n.t("403_ERROR_MESSAGE");
      }
      throw error;
    }
    // handle different error response status
    if (
      error &&
      error.response &&
      [401, 403].includes(error.response.status)
    ) {
      error.message = null;
    } else if (error && error.response && error.response.status === 404) {
      error.message = i18n.t("404_ERROR_MESSAGE");
    } else if (error && error.response && error.response.status === 409) {
      error.message = i18n.t("409_ERROR_MESSAGE");
    } else if (error && error.response && error.response.status === 500) {
      error.message = i18n.t("500_ERROR_MESSAGE");
    }
    throw error;
  }
};
