import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import IconButton from "@material-ui/core/IconButton";
import Checkbox from "@material-ui/core/Checkbox";
import Box from "@material-ui/core/Box";
import Slide from "@material-ui/core/Slide";
import CheckIcon from "@material-ui/icons/Check";
import { useSnackbar } from "notistack";

import useStyles from "./ZipSearch.styles";
import { PRIVACY_POLICY_LINK } from "../../../Constants";
export default function ZipCodeSearch(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [privacyCheck, setPrivacyCheck] = useState(false);
  const { setZipCode, setOpenModal, zipCodeValue } = props;
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const handleClick = () => {
    if (zipCodeValue.length < 4) {
      enqueueSnackbar(t("INVALID_ZIP"), {
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "center"
        },
        autoHideDuration: 2000,
        TransitionComponent: Slide
      });
    } else {
      setOpenModal(true);
    }
  };
  const handleZipCodeInput = (e) => {
    let input = e.target.value;
    if (
      !input ||
      (input[input.length - 1].match("[0-9]") && input[0].match("[1-9]"))
    ) {
      setZipCode(input);
    }
  };

  return (
    <Box className={classes.mainContainer}>
      <h3 className={classes.title}>{t("SERVICE_TITLE")}</h3>
      <OutlinedInput
        onChange={handleZipCodeInput}
        className={classes.zipTextInput}
        value={zipCodeValue}
        placeholder="Zip Code"
        inputProps={{ maxLength: 5 }}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              className={
                privacyCheck
                  ? `${classes.zipSearchBtn} ${classes.zipSearchBtnEnable}`
                  : classes.zipSearchBtn
              }
              disabled={!privacyCheck}
              onClick={handleClick}
              edge="end"
            >
              <ArrowForwardIcon className={classes.goIcon} />
            </IconButton>
          </InputAdornment>
        }
      />
      <div className={classes.checkboxWrapper}>
        <Checkbox
          disableRipple
          icon={<span className={classes.checkBox} />}
          checkedIcon={
            <Box className={`${classes.checkBox} ${classes.checkedChecked}`}>
              <CheckIcon className={classes.tickIcon} />
            </Box>
          }
          onChange={() => setPrivacyCheck(!privacyCheck)}
        />
        <span className={classes.policyText}>
          I agree to the{" "}
          <a href={PRIVACY_POLICY_LINK} target="_blank">
            {t("PRIVACY_POLICY")}
          </a>{" "}
          of this website
        </span>
      </div>
    </Box>
  );
}
