import React from "react";
import useStyles from "./Enquiry.styles";
import { Typography, Grid, Checkbox } from "@material-ui/core";
import { InputField } from "jangl-common-js";
import { useTranslation } from "react-i18next";
import theme from "../../../CustomTheme.js";
import { PRIVACY_POLICY_LINK } from "../../../Constants";
import CheckIcon from "@material-ui/icons/Check";

const StepFive = ({
  stepFiveData,
  handleStepFiveData,
  errorHandler,
  error
}) => {
  const classes = useStyles();
  const { firstName, lastName, contactNo, email, privacyCheck } = stepFiveData;
  const { t } = useTranslation();
  return (
    <>
      <Typography className={classes.question}>
        {t("CONTACT.CONTACT_YOU")}
      </Typography>
      <Grid container>
        <Grid item xs={12} md={6} className={classes.bottomSpacing}>
          <InputField
            error={error.firstName}
            inputProps={{ maxLength: 50 }}
            theme={theme}
            onChange={(e) => {
              let input = e.target.value;
              if (
                !input ||
                input.trim().length
              ) {
                handleStepFiveData({
                  ...stepFiveData,
                  firstName: input
                });
              }

              errorHandler({ ...error, firstName: "" });
            }}
            variant="standard"
            value={firstName}
            disableUnderline={false}
            className={classes.textInputFull}
            placeholder={t("CONTACT.FIRST_NAME")}
          />
        </Grid>
        <Grid item xs={12} md={6} className={classes.bottomSpacing}>
          <InputField
            error={error.lastName}
            theme={theme}
            inputProps={{ maxLength: 50 }}
            onChange={(e) => {
              let input = e.target.value;
              if (
                !input ||
                input.trim().length
              ) {
                handleStepFiveData({ ...stepFiveData, lastName: input });
              }
              errorHandler({ ...error, lastName: "" });
            }}
            variant="standard"
            value={lastName}
            disableUnderline={false}
            className={classes.textInputFull}
            placeholder={t("CONTACT.LAST_NAME")}
          />
        </Grid>
        <Grid item xs={12} md={6} className={classes.bottomSpacingXs}>
          <InputField
            error={error.contactNo}
            theme={theme}
            inputProps={{ maxLength: 20 }}
            type="tel"
            onChange={(e) => {
              let input = e.target.value;
              if (
                !input ||
                (input[input.length - 1].match("[0-9]") &&
                  input[0].match("[1-9]"))
              ) {
                handleStepFiveData({
                  ...stepFiveData,
                  contactNo: e.target.value
                });
                errorHandler({ ...error, contactNo: "" });
              }
            }}
            variant="standard"
            value={contactNo}
            disableUnderline={false}
            className={classes.textInputFull}
            placeholder={t("CONTACT.CONTACT_NO")}
          />
        </Grid>
        <Grid item xs={12} md={6} className={classes.bottomSpacingXs}>
          <InputField
            error={error.email}
            theme={theme}
            onChange={(e) => {
              let input = e.target.value;
              if (
                !input ||
                input.trim().length
              ) {
                handleStepFiveData({ ...stepFiveData, email: input });
              }
              errorHandler({ ...error, email: "" });
            }}
            variant="standard"
            value={email}
            disableUnderline={false}
            className={classes.textInputFull}
            placeholder={t("CONTACT.EMAIL")}
          />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <div className={classes.checkboxWrapper}>
          <Checkbox
            disableRipple
            icon={<Grid className={classes.checkBox} />}
            checkedIcon={
              <Grid className={`${classes.checkBox} ${classes.checkedChecked}`}>
                <CheckIcon className={classes.tickIcon} />
              </Grid>
            }
            onChange={() =>
              handleStepFiveData({
                ...stepFiveData,
                privacyCheck: !privacyCheck
              })
            }
          />
          <a
            href={PRIVACY_POLICY_LINK}
            target="_blank"
            className={classes.checkText}
          >
            {t("CONTACT.I_AGREE")}
          </a>
        </div>
        <span className={classes.policyText}>
          {t("CONTACT.TERMS")}
          <a
            href={PRIVACY_POLICY_LINK}
            target="_blank"
            className={classes.policyLink}
          >
            {t("PRIVACY_POLICY")}
          </a>
          {t("CONTACT.TERMS_PART")}
        </span>
      </Grid>
    </>
  );
};
export default StepFive;
