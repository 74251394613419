import { createTheme } from "@material-ui/core/styles";

export default createTheme({
  typography: {
    fontSize: 16,
    fontFamily: "Raleway",
    weights: {
      light: 300,
      regular: 400,
      medium: 500,
      semiBold: 600,
      bold: 700
    },
    lineHeight: {
      heading: 1.25,
      title: 1.4,
      body: 1.625,
      button: 1.5,
      bodyLarge: 2.5
    }
  },
  palette: {
    primary: {
      main: "#F4BC17",
      //main: "#3E4147",
      white: "#FFFFFF",
      yellow: "#F4BC17",
      button: "#3E1116",
      grey: "#CCCCCC",
      greyBorder: "#C7C7C7",
      disabled: "#E2E2E2",
      dark_grey:"#727272",
      grey_text:"#444444",
      blue:"#0080FF"
    },
    contrastThreshold: 3,
    tonalOffset: 0.2
  }
});
