import React from "react";
import useStyles from "./Enquiry.styles";
import { Typography, Grid } from "@material-ui/core";
import { InputField } from "jangl-common-js";
import { useTranslation } from "react-i18next";
import theme from "../../../CustomTheme.js";
import clsx from "clsx";

const StepFour = ({
  stepFourData,
  handleStepFourData,
  errorHandler,
  error
}) => {
  const classes = useStyles();
  const { zip, description, apartment, street } = stepFourData;
  const { t } = useTranslation();
  return (
    <>
      <Grid container>
        <Typography className={classes.question}>
          {t("LOCATION.ANYTHING_ELSE")}
        </Typography>
        <Grid item xs={12} className={classes.bottomSpacing}>
          <InputField
            theme={theme}
            multiline
            onChange={(e) =>
              handleStepFourData({
                ...stepFourData,
                description: e.target.value
              })
            }
            variant="standard"
            value={description}
            disableUnderline={false}
            className={clsx(classes.textInputFull, classes.fullWidth)}
            placeholder={t("LOCATION.PROJECT_DES")}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography className={clsx(classes.question, classes.topSpacing)}>
            {t("LOCATION.QUESTION")}
          </Typography>
        </Grid>

        <Grid item xs={12} md={6} className={classes.bottomSpacing}>
          <InputField
            error={error.apartment}
            theme={theme}
            onChange={(e) => {
              let input = e.target.value;
              if (
                !input ||
                input.trim().length
              ) {
                handleStepFourData({
                  ...stepFourData,
                  apartment: input
                });
              }
              errorHandler({ ...error, apartment: "" });
            }}
            variant="standard"
            value={apartment}
            inputProps={{ maxLength: 150 }}
            disableUnderline={false}
            className={classes.textInputFull}
            placeholder={t("LOCATION.PLACEHOLDER")}
          />
        </Grid>
        <Grid item xs={12} md={6} className={classes.bottomSpacing}>
          <InputField
            theme={theme}
            onChange={(e) => {
              let input = e.target.value;
              if (
                !input ||
                input.trim().length
              ) {
                handleStepFourData({ ...stepFourData, street: input })
              }
            }
            }
            variant="standard"
            value={street}
            disableUnderline={false}
            inputProps={{ maxLength: 150 }}
            className={classes.textInputFull}
            placeholder={t("LOCATION.PLACEHOLDER_APARTMENT")}
          />
        </Grid>
      </Grid>
      <Typography className={clsx(classes.zipLabel)}>
        {t("LOCATION.ZIPCODE_LABEL")}
      </Typography>
      <InputField
        error={error.zip}
        type="tel"
        theme={theme}
        onChange={(e) => {
          let input = e.target.value;
          if (
            !input ||
            (input[input.length - 1].match("[0-9]") && input[0].match("[1-9]"))
          ) {
            handleStepFourData({ ...stepFourData, zip: e.target.value });
            errorHandler({ ...error, zip: "" });
          }
        }}
        variant="standard"
        value={zip}
        disableUnderline={false}
        inputProps={{
          pattern: "[0-9]*",
          maxLength: 5
        }}
        className={classes.zipTextInput}
        placeholder={t("ZIPCODE")}
      />
    </>
  );
};
export default StepFour;
