import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  sectionHeading: {
    color: theme.palette.primary.white,
    fontSize: "2.125rem",
    fontFamily: "Raleway",
    position: "relative",
    [theme.breakpoints.between("xs", "sm")]: {
      display: "none"
    }
  },
  sectionHeadingWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100px",
    backgroundColor: theme.palette.primary.main,
    [theme.breakpoints.between("xs", "sm")]: {
      height: "50px"
    }
  },
  imageTextCover: {
    marginBottom: "2rem"
  },
  mediaTextMainGrid: {
    width: "90%",
    margin: "auto",
    justifyContent: "space-evenly",
    alignItems: "center"
  },
  mediaTextTitle: {
    position: "relative",
    margin: "50px 0",
    fontSize: "1.25rem",
    fontFamily: "Raleway",
    fontWeight: theme.typography.weights.semiBold,
    "&::after": {
      content: '""',
      position: "absolute",
      left: "48%",
      bottom: -12,
      height: "2px",
      width: "4ch",
      color: "red",
      backgroundColor: "#f1bb45"
    },
    [theme.breakpoints.between("xs", "sm")]: {
      fontSize: "1.2rem"
    }
  },
  mediaTextSubtitle: {
    marginBottom: 0,
    textAlign: "left",
    fontSize: "0.93rem",
    fontFamily: "Raleway",
    fontWeight: theme.typography.weights.semiBold,
    [theme.breakpoints.between("xs", "sm")]: {
      marginBottom: 10,
      textAlign: "center"
    }
  },
  mediaTextPara: {
    width: "50ch",
    textAlign: "left",
    margin: "auto",
    fontSize: "0.875rem",
    fontFamily: "Raleway",
    color: "#444444",
    lineHeight: theme.typography.lineHeight.body,
    [theme.breakpoints.between("xs", "sm")]: {
      textAlign: "center",
      maxWidth: "90%",
      margin: "auto"
    }
  },
  textWrapper: {
    marginBottom: "2rem"
  },
  imageComponent: {
    maxWidth: "70%",
    height: "auto",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "18rem",
      textAlign: "center"
    }
  }
}));
