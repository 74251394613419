import React from "react";
import { Switch, Redirect } from "react-router-dom";
import CustomRoute from "./CustomRoute";
import MainPage from "../MainPage/MainPage";

export const CustomRoutes = () => {

  const NotFoundRedirect = () => <Redirect to="/error" />;

  return (
    <Switch>
      <CustomRoute path='/' component={MainPage} />
      <CustomRoute component={NotFoundRedirect} />
    </Switch>
  );
};

export default CustomRoutes;
