import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  Typography,
  Stepper,
  Step,
  StepLabel,
  Slide,
  CircularProgress
} from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import useStyles from "./Enquiry.styles";
import {
  PURCHASE_TIME_FRAME,
  RESIDENTIAL_INFORMATION
} from "../../../Constants";
import { useSnackbar } from "notistack";
import StepOne from "./StepOne.component";
import StepTwo from "./StepTwo.component";
import StepThree from "./StepThree.component";
import StepFour from "./StepFour.component";
import StepFive from "./StepFive.component";
import StepConfirmation from "./Confirmation.component";
import { saveForm } from "../../../services/api";
import { emailValidate } from "../../../services/utility";

export default function Enquiry({ project, resetHandler, zipcode }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const steps = [1, 2, 3, 4, 5];
  const [activeStep, setActiveStep] = useState(0);
  const [next, setNext] = useState(1);
  const [moveToNext, setMoveToNext] = useState(false);

  const [stepFourData, setStepFourData] = useState({
    zip: zipcode,
    description: "",
    apartment: "",
    street: ""
  });
  const [stepFiveData, setStepFiveData] = useState({
    firstName: "",
    lastName: "",
    contactNo: "",
    email: "",
    privacyCheck: false
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({
    zip: "",
    apartment: "",
    contactNo: "",
    firstName: "",
    lastName: "",
    email: ""
  });
  const [stepAnswers, setStepAnswers] = useState({
    dropdown1: "",
    dropdown2: "",
    dropdown3: "",
    Timeframe: "",
    Residential: "",
    Homeowner: ""
  });
  const stepTwoData = PURCHASE_TIME_FRAME;
  const stepThreeData = RESIDENTIAL_INFORMATION;
  const { zip, description, apartment, street } = stepFourData;
  const { firstName, lastName, contactNo, email, privacyCheck } = stepFiveData;

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const data = {
    meta: {},
    contact: {
      first_name: firstName,
      last_name: lastName,
      email: email,
      phone: contactNo,
      address: street,
      address2: apartment,
      zip_code: zip
    },
    data: {
      best_call_time: "Any time",
      own_property: stepAnswers["Homeowner"] === "Yes" ? true : false,
      purchase_time_frame: stepAnswers["Timeframe"],
      extra_data: { note: description, is_residential: stepAnswers["Residential"] === "Yes" ? true : false } ,
      roof: project?.value === "roofing" ? {
        project_type: stepAnswers["dropdown1"].replaceAll(" ", "\u002B"),
        roofing_type: stepAnswers["dropdown2"].replaceAll(" ", "\u002B")
      } : null,
      windows: project?.value === "windows" ? {
        "project_type": stepAnswers["dropdown1"].replaceAll(" ", "\u002B"),
        "num_windows": stepAnswers["dropdown2"].replaceAll(" ", "\u002B")
      } : null,
      hvac: project?.value === "hvac" ? {
        "project_type": stepAnswers["dropdown1"].replaceAll(" ", "\u002B"),
        "air_type": stepAnswers["dropdown2"].replaceAll(" ", "\u002B"),
        "system_type": stepAnswers["dropdown3"].replaceAll(" ", "\u002B")
      } : null
    }
  };

  const getQuotes = () => {
    setLoading(true);
    //setActiveStep(5);
    saveForm(data)
      .then((result) => {
        setLoading(false);
        setActiveStep(5);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (activeStep === 0) {
      stepAnswers[`dropdown${next}`].length
        ? setMoveToNext(true)
        : setMoveToNext(false);
    } else if (activeStep === 1) {
      stepAnswers["Timeframe"].length
        ? setMoveToNext(true)
        : setMoveToNext(false);
    } else if (activeStep === 2) {
      stepAnswers["Residential"].length && stepAnswers["Homeowner"]
        ? setMoveToNext(true)
        : setMoveToNext(false);
    } else if (activeStep === 3) {
      if (zip.length && apartment.length) {
        setMoveToNext(true);
      } else {
        setMoveToNext(false);
      }
    } else if (activeStep === 4) {
      firstName.length &&
        lastName.length &&
        emailValidate(email) &&
        contactNo.length &&
        privacyCheck
        ? setMoveToNext(true)
        : setMoveToNext(false);
    }
  }, [next, activeStep, stepAnswers, stepFourData, stepFiveData]);

  const radioWarning = (msg) =>
    enqueueSnackbar(t(msg), {
      variant: "warning",
      anchorOrigin: {
        vertical: "top",
        horizontal: "center"
      },
      autoHideDuration: 2000,
      TransitionComponent: Slide
    });
  const handleNext = () => {
    if (activeStep === 0) {
      if (next < project?.questions?.length) {
        moveToNext ? setNext(next + 1) : radioWarning("SELECET_WARNING");
      } else if (activeStep < 5) {
        moveToNext ? setActiveStep(1) : radioWarning("SELECET_WARNING");
      }
    }
    if (activeStep === 1) {
      moveToNext ? setActiveStep(2) : radioWarning("SELECET_WARNING");
    }
    if (activeStep === 2) {
      moveToNext ? setActiveStep(3) : radioWarning("SELECET_WARNING");
    }
    if (activeStep === 3) {
      if (moveToNext) {
        setActiveStep(4);
      } else {
        radioWarning("INPUT_FIELD_WARNING");
        setError({
          ...error,
          zip: zip.length ? "" : "*Required",
          apartment: apartment.length ? "" : "*Required"
        });
      }
    }
    if (activeStep === 4) {
      if (moveToNext) {
        getQuotes();
      } else {
        if (
          !contactNo.length ||
          !emailValidate(email) ||
          !firstName.length ||
          !lastName.length
        ) {
          radioWarning("INPUT_FIELD_WARNING");
        } else {
          !privacyCheck && radioWarning("CHECK_BOX_WARNING");
        }
        setError({
          ...error,
          contactNo: contactNo.length ? "" : "*Required",
          email: email.length
            ? emailValidate(email)
              ? ""
              : "*Invalid email"
            : "*Required",
          firstName: firstName.length ? "" : "*Required",
          lastName: lastName.length ? "" : "*Required"
        });
      }
    }
  };

  // handles answers to selected for each questions from project
  const selectAnswer = ({ event, question }) => {
    setStepAnswers({ ...stepAnswers, [question]: event.target.value });
    if (activeStep !== 2) setMoveToNext(true);
  };

  const prevousAnswer = () => {
    if (activeStep === 0) {
      if (next < project?.questions?.length + 1) {
        next === 1 ? resetHandler() : setNext((prevNext) => prevNext - 1);
      }
    }
    if (activeStep >= 1) {
      setActiveStep((prevNext) => prevNext > 0 && prevNext - 1);
    }
  };
  const CustomStepper = ({ active, completed, stepNumber }) => {
    return (
      <div
        className={
          active || completed
            ? `${classes.stepperIcon}`
            : `${classes.stepperIcon} ${classes.stepperIconInActive}`
        }
      >
        {completed ? (
          <CheckIcon className={classes.stepCompletedIcon} />
        ) : (
          <span>{stepNumber + 1}</span>
        )}
      </div>
    );
  };
  return (
    <Box className={classes.modalWrapper}>
      {activeStep === 5 && <StepConfirmation submitAnother={resetHandler} />}
      <div className={classes.stepperRoot}>
        {activeStep !== 5 && (
          <Stepper activeStep={activeStep} >
            {steps.map((label, index) => (
              <Step key={label} className={classes.stepperPadding}>
                <StepLabel 
                  StepIconComponent={(props) => (
                    <CustomStepper stepNumber={index} {...props} />
                  )}
                >
                  {0}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        )}
      </div>
      {activeStep === 0 ? (
        <div className={classes.questionPopupWrapper}>
          <StepOne
            data={project}
            next={next}
            selectAnswer={selectAnswer}
            stepAnswers={stepAnswers}
          />
        </div>
      ) : (
        <div className={classes.lastStepContainer}>
          {activeStep === 1 && (
            <StepTwo
              stepTwoData={stepTwoData}
              selectAnswer={selectAnswer}
              stepAnswers={stepAnswers}
            />
          )}
          {activeStep === 2 && (
            <StepThree
              stepThreeData={stepThreeData}
              selectAnswer={selectAnswer}
              stepAnswers={stepAnswers}
            />
          )}
          {activeStep === 3 && (
            <StepFour
              error={error}
              stepFourData={stepFourData}
              handleStepFourData={setStepFourData}
              errorHandler={setError}
            />
          )}
          {activeStep === 4 && (
            <StepFive
              error={error}
              stepFiveData={stepFiveData}
              handleStepFiveData={setStepFiveData}
              errorHandler={setError}
            />
          )}
        </div>
      )}

      {activeStep !== 5 && (
        <div className={classes.btnWrapper}>
          <Button onClick={() => prevousAnswer()}>
            <Typography variant="body1" className={classes.prevBtn}>
              {t("PREVIOUS")}
            </Typography>
          </Button>
          {activeStep !== 4 && (
            <Button
              variant="contained"
              className={classes.nextBtn}
              onClick={() => handleNext()}
            >
              {t("NEXT")}
            </Button>
          )}
          {activeStep === 4 && (
            <Button
              variant="contained"
              className={classes.nextBtn}
              onClick={() => handleNext()}
            >
              {loading ? (
                <CircularProgress size={16} color="white" />
              ) : (
                t("GET_QUOTES")
              )}
            </Button>
          )}
        </div>
      )}
    </Box>
  );
}
