import React from "react";
import useStyles from "./Enquiry.styles";
import {
  Box,
  FormControlLabel,
  RadioGroup,
  Typography
} from "@material-ui/core";
import RadioButton from "../CustomRadio/CustomRadio.component";

const StepOne = ({ data, next, selectAnswer, stepAnswers }) => {
  const classes = useStyles();
  return data?.questions?.map((projectData, index) => (
    <div
      className={
        next === index + 1
          ? `${classes.container} ${classes.activeData}`
          : classes.container
      }
    >
      <Typography
        variant="h5"
        className={`${classes.question} ${classes.questionLabel}`}
      >
        {projectData.title}
      </Typography>

      <RadioGroup
        row
        name="controlled-radio-buttons-group"
        value={stepAnswers[`dropdown${next}`]}
        onChange={(event) =>
          selectAnswer({ event, question: `dropdown${next}` })
        }
      >
        {projectData.answers.map((answer, index) => {
          return (
            <Box className={classes.radioBoxCover} key={index + 1}>
              <FormControlLabel
                className={classes.formLabelStyle}
                checked={answer.toString() === stepAnswers[`dropdown${next}`]}
                value={answer}
                control={<RadioButton />}
                label={<span className={classes.answerLabel}>{answer}</span>}
              />
            </Box>
          );
        })}
      </RadioGroup>
    </div>
  ));
};
export default StepOne;
