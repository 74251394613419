import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    overflowY: "scroll"
  },
  paper: {
    background: `${theme.palette.primary.white} 0% 0% no-repeat padding-box`,
    boxShadow: "0px 3px 10px #00000029",
    padding: theme.spacing(3, 3, 3, 3),
    borderRadius: theme.spacing(1),
    outline: "none",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center"
  },
  header: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end"
  },
  contentWithTitle: {
    marginTop: "-38px",
    minWidth: "250px"
  },
  contentWithoutTitle: {
    minWidth: "250px"
  },
  closeIcon: {
    cursor: "pointer",
    color: theme.palette.primary.button
  },
  btn: {
    marginLeft: "auto",
    marginRight: theme.spacing(2),
    float: "right",
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.white,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.white
    }
  }
}));
