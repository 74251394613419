import React from "react";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import { loaderSelectors } from "../../store/loader";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: 0,
    backgroundColor: theme.palette.primary.white,
    color: theme.palette.primary.gray,
  },
  content: {
    display: "block",
  },
}));

export default function Loading(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const showLoader = useSelector(loaderSelectors.getLoader) !== 0;

  return (
    <Backdrop className={classes.backdrop} open={showLoader}>
      <div className={classes.content}>
        <CircularProgress color="inherit" />
        <div>{t("LOADING")}</div>
      </div>
    </Backdrop>
  );
}
